@charset "UTF-8";

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,600;1,100;1,200;1,300;1,500&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css");

/* #page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1002;
  background-color: #333;
  border-bottom: 1px solid #333;
} */
.navbar-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  height: 70px;
  padding: 0 20px 0 0;
}
.navbar-header .dropdown .show.header-item {
  background-color: #f8f9fa;
}
.navbar-brand-box {
  padding: 0 1.5rem;
  width: 250px;
  background: #333;
  border-right: 1px solid #333;
}
.logo {
  line-height: 69px;
  color: inherit !important;
}
.logo .logo-sm {
  display: none;
}
.logo-txt {
  font-weight: 700;
  font-size: 18px;
  vertical-align: middle;
  margin-left: 5px;
}
.logo-light {
  display: none;
}
.card-body label {
  text-transform: capitalize;
}
.alert-box {
  position: fixed;
  bottom: 10%;
  right: 0;
  z-index: 999;
}
.card-title span {
  color: #999;
  font-size: 12px;
}
.title {
  color: #999;
  text-transform: capitalize;
}
.card {
  box-shadow: rgb(227 233 243) 0px 4px 22px;
}
/* body:not([data-sidebar-size="sm"]) #vertical-menu-btn {
  margin-left: -52px;
  margin-right: 20px;
} */
.filters {
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  padding: 10px;
  margin: 10px;
}
.btn-primary,
.btn-light {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-primary:hover,
.btn-light:hover {
  color: #fff;
  background-color: #333;
  border-color: #333;
}

.page-content {
  padding: 90px 10px 5px;
}
.header-item {
  height: 70px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 0;
  border-radius: 0;
}
.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  padding-right: 0.5rem;
  color: #74788d;
  content: ">";
}
.header-item,
.header-item:hover {
  color: #fff;
}
.header-profile-user {
  height: 50px;
  width: 50px;
  padding: 3px;
}

.page-title-box {
  padding-bottom: 20px;
}
.page-title-box .breadcrumb {
  background-color: transparent;
  padding: 0;
}
.footer {
  bottom: 0;
  padding: 20px 10px;
  position: absolute;
  right: 0;
  color: #74788d;
  left: 250px;
  height: 60px;
  background-color: #fff;
  border-top: 1px solid #e9e9ef;
}
@media (max-width: 991.98px) {
  .footer {
    left: 0;
  }
}
body[data-sidebar-size="sm"] .footer {
  left: 70px;
}
@media (max-width: 991.98px) {
  body[data-sidebar-size="sm"] .footer {
    left: 0;
  }
}
body[data-layout="horizontal"] .footer {
  left: 0 !important;
}
body[data-layout-mode="dark"] .footer {
  background-color: #313533;
  color: #adb5bd;
  border-color: #373c39;
}
.right-bar {
  background-color: #fff;
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06),
    0 1px 0 0 rgba(0, 0, 0, 0.02);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  display: block;
  position: fixed;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  width: 300px;
  z-index: 9999;
  float: right !important;
  right: -310px;
  top: 0;
  bottom: 0;
}
.right-bar .right-bar-toggle {
  background-color: #444c54;
  height: 24px;
  width: 24px;
  line-height: 24px;
  display: block;
  color: #e9e9ef;
  text-align: center;
  border-radius: 50%;
}
.right-bar .right-bar-toggle:hover {
  background-color: #4b545c;
}
.rightbar-overlay {
  background-color: rgba(52, 58, 64, 0.55);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 9998;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.right-bar-enabled .right-bar {
  right: 0;
}
.right-bar-enabled .rightbar-overlay {
  display: block;
}
@media (max-width: 767.98px) {
  .right-bar {
    overflow: auto;
  }
  .right-bar .slimscroll-menu {
    height: auto !important;
  }
}
body[data-layout-mode="dark"] .right-bar {
  background-color: #313533;
}
.metismenu {
  margin: 0;
}
.metismenu li {
  display: block;
  width: 100%;
}
.metismenu .mm-collapse,
.metismenu .mm-collapse:not(.mm-show) {
  display: none;
}
.metismenu .mm-collapse.mm-show {
  display: block;
}
.metismenu .mm-collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
}
.vertical-menu {
  width: 250px;
  z-index: 1001;
  background: #fbfaff;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 70px;
  border-right: 1px solid #e9e9ef;
}
.main-content {
  margin-left: 250px;
  overflow: hidden;
}
.main-content .content {
  padding: 0 15px 10px;
  margin-top: 70px;
}
#sidebar-menu {
  padding: 10px 0 30px;
}
#sidebar-menu .mm-active > .has-arrow:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
#sidebar-menu .has-arrow:after {
  content: "󰅂";
  font-family: Material Design Icons;
  display: block;
  float: right;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  font-size: 1.1rem;
  margin-right: -5px;
  margin-top: -2px;
}
#sidebar-menu ul li a {
  display: block;
  padding: 0.62rem 1.5rem;
  position: relative;
  font-size: 0.9rem;
  font-weight: 500;
}
#sidebar-menu ul li a,
#sidebar-menu ul li a i {
  color: #545a6d;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
#sidebar-menu ul li a i {
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
}
#sidebar-menu ul li a svg {
  height: 16px;
  width: 16px;
  color: #545a6d;
  fill: rgba(84, 90, 109, 0.2);
  margin-right: 10px;
  margin-top: -3px;
}
#sidebar-menu ul li a:hover,
#sidebar-menu ul li a:hover i {
  color: #5156be;
}
#sidebar-menu ul li a:hover svg {
  color: #5156be;
  fill: rgba(81, 86, 190, 0.2);
}
#sidebar-menu ul li .badge {
  margin-top: 4px;
}
#sidebar-menu ul li ul.sub-menu {
  padding: 0;
}
#sidebar-menu ul li ul.sub-menu li a {
  padding: 0.4rem 1.5rem 0.4rem 3.3rem;
  font-size: 0.85rem;
  color: #545a6d;
}
#sidebar-menu ul li ul.sub-menu li a:hover {
  color: #5156be;
}
#sidebar-menu ul li ul.sub-menu li ul.sub-menu {
  padding: 0;
}
#sidebar-menu ul li ul.sub-menu li ul.sub-menu li a {
  padding: 0.4rem 1.5rem 0.4rem 4.5rem;
  font-size: 13px;
}
.menu-title {
  padding: 12px 20px !important;
  pointer-events: none;
  cursor: default;
  font-size: 12px;
  color: #545a6d;
  font-weight: 500;
}
.mm-active > a,
.mm-active > a i,
.mm-active > a svg {
  color: #5156be !important;
}
.mm-active > a svg {
  fill: rgba(81, 86, 190, 0.2) !important;
}
.mm-active .active,
.mm-active .active i,
.mm-active .active svg {
  color: #5156be !important;
}
.mm-active .active svg {
  fill: rgba(81, 86, 190, 0.2) !important;
}
.mm-active > i {
  color: #5156be !important;
}
.sidebar-alert {
  background-color: rgba(122, 127, 220, 0.1);
}
.sidebar-alert .alertcard-title {
  color: #7a7fdc;
}
@media (max-width: 992px) {
  .vertical-menu {
    display: none;
  }
  .main-content {
    margin-left: 0 !important;
  }
  body.sidebar-enable .vertical-menu {
    display: block;
  }
}
body[data-sidebar-size="sm"] {
  min-height: 1000px;
}
body[data-sidebar-size="sm"] .main-content {
  margin-left: 70px;
}
body[data-sidebar-size="sm"] .navbar-brand-box {
  width: 70px !important;
}
body[data-sidebar-size="sm"] .logo span.logo-lg {
  display: none;
}
body[data-sidebar-size="sm"] .logo span.logo-sm {
  display: block;
}
body[data-sidebar-size="sm"] .vertical-menu {
  position: absolute;
  width: 70px !important;
  z-index: 5;
}
body[data-sidebar-size="sm"] .vertical-menu .simplebar-content-wrapper,
body[data-sidebar-size="sm"] .vertical-menu .simplebar-mask {
  overflow: visible !important;
}
body[data-sidebar-size="sm"] .vertical-menu .simplebar-scrollbar {
  display: none !important;
}
body[data-sidebar-size="sm"] .vertical-menu .simplebar-offset {
  bottom: 0 !important;
}
body[data-sidebar-size="sm"] .vertical-menu #sidebar-menu .badge,
body[data-sidebar-size="sm"] .vertical-menu #sidebar-menu .menu-title,
body[data-sidebar-size="sm"] .vertical-menu #sidebar-menu .sidebar-alert {
  display: none !important;
}
body[data-sidebar-size="sm"] .vertical-menu #sidebar-menu .nav.collapse {
  height: inherit !important;
}
body[data-sidebar-size="sm"] .vertical-menu #sidebar-menu > ul > li {
  position: relative;
  white-space: nowrap;
}
body[data-sidebar-size="sm"] .vertical-menu #sidebar-menu > ul > li > a {
  padding: 15px 20px;
  -webkit-transition: none;
  transition: none;
}
body[data-sidebar-size="sm"] .vertical-menu #sidebar-menu > ul > li > a:active,
body[data-sidebar-size="sm"] .vertical-menu #sidebar-menu > ul > li > a:focus,
body[data-sidebar-size="sm"] .vertical-menu #sidebar-menu > ul > li > a:hover {
  color: #5156be;
}
body[data-sidebar-size="sm"] .vertical-menu #sidebar-menu > ul > li > a i {
  font-size: 1.45rem;
  margin-left: 4px;
}
body[data-sidebar-size="sm"] .vertical-menu #sidebar-menu > ul > li > a svg {
  height: 18px;
  width: 18px;
  margin-left: 6px;
}
body[data-sidebar-size="sm"] .vertical-menu #sidebar-menu > ul > li > a span {
  display: none;
  padding-left: 25px;
}
body[data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  > ul
  > li
  > a.has-arrow:after {
  display: none;
}
body[data-sidebar-size="sm"] .vertical-menu #sidebar-menu > ul > li:hover > a {
  position: relative;
  width: 260px;
  color: #7a7fdc;
  background-color: #ebe6ff;
  -webkit-transition: none;
  transition: none;
}
body[data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  > ul
  > li:hover
  > a
  i {
  color: #7a7fdc;
}
body[data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  > ul
  > li:hover
  > a
  svg {
  color: #5156be;
  fill: rgba(81, 86, 190, 0.2);
}
body[data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  > ul
  > li:hover
  > a
  span {
  display: inline;
}
body[data-sidebar-size="sm"] .vertical-menu #sidebar-menu > ul > li:hover > ul {
  display: block;
  left: 70px;
  position: absolute;
  width: 190px;
  height: auto !important;
}
body[data-sidebar-size="sm"] .vertical-menu #sidebar-menu > ul > li:hover > ul,
body[data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  > ul
  > li:hover
  > ul
  ul {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
body[data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  > ul
  > li:hover
  > ul
  a {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 8px 20px;
  position: relative;
  width: 190px;
  z-index: 6;
  color: #545a6d;
}
body[data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  > ul
  > li:hover
  > ul
  a:hover {
  color: #5156be;
}
body[data-sidebar-size="sm"] .vertical-menu #sidebar-menu > ul ul {
  padding: 5px 0;
  z-index: 9999;
  display: none;
  background-color: #fff;
}
body[data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  > ul
  ul
  li:hover
  > ul {
  display: block;
  left: 190px;
  height: auto !important;
  margin-top: -36px;
  position: absolute;
  width: 190px;
  padding: 5px 0;
}
body[data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  > ul
  ul
  li
  > a
  span.pull-right {
  position: absolute;
  right: 20px;
  top: 12px;
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
body[data-sidebar-size="sm"] .vertical-menu #sidebar-menu > ul ul li.active a {
  color: #f8f9fa;
}
body[data-sidebar-size="sm"] #sidebar-menu .mm-active > .has-arrow:after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
body[data-sidebar="dark"] .navbar-brand-box {
  background: #2c302e;
  -webkit-box-shadow: 0 3px 1px #2c302e;
  box-shadow: 0 3px 1px #2c302e;
  border-color: #2c302e;
}
body[data-sidebar="dark"] .navbar-brand-box .logo {
  color: #fff !important;
}
body[data-sidebar="dark"] .logo-dark {
  display: none;
}
body[data-sidebar="dark"] .logo-light {
  display: block;
}
body[data-sidebar="dark"] .vertical-menu {
  background: #2c302e;
  border-color: #2c302e;
}
@media (min-width: 992px) {
  body[data-sidebar="dark"] #vertical-menu-btn {
    color: #e9ecef;
  }
}
body[data-sidebar="dark"] #sidebar-menu ul li a {
  color: #99a4b1;
}
body[data-sidebar="dark"] #sidebar-menu ul li a i {
  color: #858d98;
}
body[data-sidebar="dark"] #sidebar-menu ul li a svg {
  color: #858d98;
  fill: rgba(133, 141, 152, 0.2);
}
body[data-sidebar="dark"] #sidebar-menu ul li a:hover,
body[data-sidebar="dark"] #sidebar-menu ul li a:hover i {
  color: #fff;
}
body[data-sidebar="dark"] #sidebar-menu ul li a:hover svg {
  color: #fff;
  fill: hsla(0, 0%, 100%, 0.2);
}
body[data-sidebar="dark"] #sidebar-menu ul li ul.sub-menu li a {
  color: #858d98;
}
body[data-sidebar="dark"] #sidebar-menu ul li ul.sub-menu li a:hover {
  color: #fff;
}
body[data-sidebar="dark"][data-sidebar-size="sm"][data-topbar="dark"]
  #vertical-menu-btn {
  color: #e9ecef;
}
body[data-sidebar="dark"][data-sidebar-size="sm"] #vertical-menu-btn {
  color: #555b6d;
}
body[data-sidebar="dark"][data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  > ul
  > li:hover
  > a {
  background: #313533;
  color: #fff;
}
body[data-sidebar="dark"][data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  > ul
  > li:hover
  > a
  i {
  color: #fff;
}
body[data-sidebar="dark"][data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  > ul
  > li:hover
  > a
  svg {
  color: #fff;
  fill: hsla(0, 0%, 100%, 0.2);
}
body[data-sidebar="dark"][data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  > ul
  > li:hover
  > ul
  a {
  color: #858d98;
}
body[data-sidebar="dark"][data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  > ul
  > li:hover
  > ul
  a:hover {
  color: #fff;
}
body[data-sidebar="dark"][data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  > ul
  ul {
  background-color: #2c302e;
}
body[data-sidebar="dark"] .mm-active,
body[data-sidebar="dark"] .mm-active > a,
body[data-sidebar="dark"] .mm-active > a i,
body[data-sidebar="dark"][data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  ul
  li.mm-active
  .active,
body[data-sidebar="dark"][data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  ul
  li.mm-active
  .active
  i {
  color: #fff !important;
}
body[data-sidebar="dark"] .mm-active > a svg {
  color: #fff !important;
  fill: hsla(0, 0%, 100%, 0.2) !important;
}
body[data-sidebar="dark"] .mm-active .active,
body[data-sidebar="dark"] .mm-active .active i,
body[data-sidebar="dark"] .mm-active > i {
  color: #fff !important;
}
body[data-sidebar="dark"] .mm-active .active svg {
  color: #fff !important;
  fill: hsla(0, 0%, 100%, 0.2) !important;
}
body[data-sidebar="dark"] .menu-title {
  color: #858d98;
}
body[data-sidebar="dark"][data-sidebar-size="md"]
  #sidebar-menu
  ul
  li.menu-title {
  background-color: #313533;
}
body[data-layout="horizontal"] .main-content {
  margin-left: 0 !important;
}
body[data-sidebar-size="md"] .navbar-brand-box {
  width: 160px;
}
@media (max-width: 991.98px) {
  body[data-sidebar-size="md"] .navbar-brand-box {
    width: auto;
  }
}
body[data-sidebar-size="md"] .vertical-menu {
  width: 160px;
  text-align: center;
}
body[data-sidebar-size="md"] .vertical-menu .badge,
body[data-sidebar-size="md"] .vertical-menu .has-arrow:after,
body[data-sidebar-size="md"] .vertical-menu .sidebar-alert {
  display: none !important;
}
body[data-sidebar-size="md"] .main-content {
  margin-left: 160px;
}
body[data-sidebar-size="md"] .footer {
  left: 160px;
}
@media (max-width: 991.98px) {
  body[data-sidebar-size="md"] .footer {
    left: 0;
  }
}
body[data-sidebar-size="md"] #sidebar-menu ul li a svg {
  display: block;
  margin: 0 auto 4px;
}
body[data-sidebar-size="md"] #sidebar-menu ul li ul.sub-menu li a,
body[data-sidebar-size="md"]
  #sidebar-menu
  ul
  li
  ul.sub-menu
  li
  ul.sub-menu
  li
  a {
  padding-left: 1.5rem;
}
body[data-sidebar-size="md"][data-sidebar-size="sm"] .main-content {
  margin-left: 70px;
}
body[data-sidebar-size="md"][data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu {
  text-align: left;
}
body[data-sidebar-size="md"][data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  > ul
  > li
  > a
  svg {
  display: inline-block;
}
body[data-sidebar-size="md"][data-sidebar-size="sm"] .footer {
  left: 70px;
}
body[data-sidebar="brand"] .vertical-menu {
  background-color: #7a7fdc;
}
body[data-sidebar="brand"] .navbar-brand-box {
  background-color: #7a7fdc;
  -webkit-box-shadow: 0 1px 0 #7a7fdc;
  box-shadow: 0 1px 0 #7a7fdc;
}
body[data-sidebar="brand"] .navbar-brand-box .logo-dark {
  display: none;
}
body[data-sidebar="brand"] .navbar-brand-box .logo-light {
  display: block;
}
body[data-sidebar="brand"] .mm-active,
body[data-sidebar="brand"] .mm-active > a,
body[data-sidebar="brand"] .mm-active > a i,
body[data-sidebar="brand"] .navbar-brand-box .logo {
  color: #fff !important;
}
body[data-sidebar="brand"] .mm-active > a svg {
  color: #fff !important;
  fill: hsla(0, 0%, 100%, 0.1) !important;
}
body[data-sidebar="brand"] .mm-active .active {
  color: #fff !important;
}
body[data-sidebar="brand"] .mm-active .active svg {
  color: #fff !important;
  fill: hsla(0, 0%, 100%, 0.1) !important;
}
@media (min-width: 992px) {
  body[data-sidebar="brand"] #vertical-menu-btn {
    color: #e9ecef;
  }
}
body[data-sidebar="brand"] #sidebar-menu ul li.menu-title,
body[data-sidebar="brand"] #sidebar-menu ul li a,
body[data-sidebar="brand"] #sidebar-menu ul li a i {
  color: hsla(0, 0%, 100%, 0.6);
}
body[data-sidebar="brand"] #sidebar-menu ul li a svg {
  color: hsla(0, 0%, 100%, 0.6);
  fill: hsla(0, 0%, 100%, 0.075);
}
body[data-sidebar="brand"] #sidebar-menu ul li a.waves-effect .waves-ripple {
  background: hsla(0, 0%, 100%, 0.1);
}
body[data-sidebar="brand"] #sidebar-menu ul li a:hover,
body[data-sidebar="brand"] #sidebar-menu ul li a:hover i {
  color: #fff;
}
body[data-sidebar="brand"] #sidebar-menu ul li ul.sub-menu li a {
  color: hsla(0, 0%, 100%, 0.5);
}
body[data-sidebar="brand"] #sidebar-menu ul li ul.sub-menu li a:hover {
  color: #fff;
}
body[data-sidebar="brand"] .sidebar-alert {
  background-color: hsla(0, 0%, 100%, 0.1);
  color: hsla(0, 0%, 100%, 0.5);
}
body[data-sidebar="brand"] .sidebar-alert .alertcard-title {
  color: #fff;
}
body[data-sidebar="brand"][data-sidebar-size="sm"][data-topbar="dark"]
  #vertical-menu-btn {
  color: #e9ecef;
}
body[data-sidebar="brand"][data-sidebar-size="sm"] #vertical-menu-btn {
  color: #555b6d;
}
body[data-sidebar="brand"][data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  > ul
  > li:hover
  > a {
  background-color: #8287de;
  color: #fff;
}
body[data-sidebar="brand"][data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  > ul
  > li:hover
  > a
  i,
body[data-sidebar="brand"][data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  > ul
  > li:hover
  > a
  svg {
  color: #fff;
}
body[data-sidebar="brand"][data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  ul
  li.mm-active
  .active {
  color: #fff !important;
}
body[data-sidebar="brand"][data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  ul
  li
  ul.sub-menu
  li
  a:hover {
  color: #5156be;
}
body[data-sidebar="brand"][data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  ul
  li
  ul.sub-menu
  li.mm-active,
body[data-sidebar="brand"][data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  ul
  li
  ul.sub-menu
  li.mm-active
  > a {
  color: #5156be !important;
}
body[data-layout-mode="dark"][data-sidebar="brand"] .navbar-brand-box,
body[data-layout-mode="dark"][data-sidebar="brand"] .vertical-menu,
body[data-layout-mode="dark"][data-sidebar="dark"] .navbar-brand-box,
body[data-layout-mode="dark"][data-sidebar="dark"] .vertical-menu {
  border-color: #373c39;
}
body[data-layout-mode="dark"][data-sidebar="light"] .sidebar-alert {
  background-color: rgba(122, 127, 220, 0.1);
  color: #495057;
}
body[data-layout-mode="dark"][data-sidebar="light"]
  .sidebar-alert
  .alertcard-title {
  color: #7a7fdc;
}
[dir="rtl"] #sidebar-menu .has-arrow:after {
  content: "󰅁";
}
.topnav {
  background: #fff;
  padding: 0 10px;
  margin-top: 70px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
  border-bottom: 1px solid #e9e9ef;
}
@media (min-width: 992px) {
  .topnav {
    background: #fbfaff;
  }
}
.topnav .topnav-menu {
  margin: 0;
  padding: 0;
}
.topnav .navbar-nav .nav-link {
  font-size: 14.4px;
  position: relative;
  padding: 10px 10px;
  color: #545a6d;
  font-weight: 500;
}
.topnav .navbar-nav .nav-link i {
  font-size: 15px;
}
.topnav .navbar-nav .nav-link svg {
  height: 16px;
  width: 16px;
  color: #545a6d;
  fill: rgba(84, 90, 109, 0.2);
  margin-right: 7px;
  margin-top: -3px;
}
.topnav .navbar-nav .nav-link:focus,
.topnav .navbar-nav .nav-link:hover {
  color: #5156be;
  background-color: transparent;
}
.topnav .navbar-nav .nav-link:focus svg,
.topnav .navbar-nav .nav-link:hover svg {
  color: #5156be;
  fill: rgba(81, 86, 190, 0.2);
}
.topnav .navbar-nav .dropdown-item {
  color: #545a6d;
}
.topnav .navbar-nav .dropdown-item.active,
.topnav .navbar-nav .dropdown-item:hover,
.topnav .navbar-nav .nav-item .nav-link.active {
  color: #5156be;
}
.topnav .navbar-nav .nav-item .nav-link.active svg {
  color: #5156be;
  fill: rgba(81, 86, 190, 0.2);
}
.topnav .navbar-nav .dropdown.active > a {
  color: #5156be;
  background-color: transparent;
}
.topnav .navbar-nav .dropdown.active > a svg {
  color: #5156be;
  fill: rgba(81, 86, 190, 0.2);
}
.topnav .menu-title {
  padding: 12px 24px !important;
}
@media (max-width: 991.98px) {
  .topnav .menu-title {
    padding: 12px 16px !important;
  }
}
@media (min-width: 1200px) {
  body[data-layout="horizontal"] .container-fluid,
  body[data-layout="horizontal"] .navbar-header {
    max-width: 85%;
  }
}
@media (min-width: 992px) {
  .topnav .navbar-nav .nav-item:first-of-type .nav-link {
    padding-left: 0;
  }
  .topnav .dropdown-item {
    padding: 0.5rem 1.5rem;
    min-width: 180px;
  }
  .topnav .dropdown.mega-dropdown .mega-dropdown-menu {
    left: 0;
    right: auto;
  }
  .topnav .dropdown .dropdown-menu {
    margin-top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
  }
  .topnav .dropdown .dropdown-menu .arrow-down:after {
    right: 15px;
    -webkit-transform: rotate(-135deg) translateY(-50%);
    transform: rotate(-135deg) translateY(-50%);
    position: absolute;
  }
  .topnav .dropdown .dropdown-menu .dropdown .dropdown-menu {
    position: absolute;
    top: 0 !important;
    left: 100%;
    display: none;
  }
  .topnav .dropdown:hover > .dropdown-menu,
  .topnav .dropdown:hover > .dropdown-menu > .dropdown:hover > .dropdown-menu {
    display: block;
  }
  .navbar-toggle {
    display: none;
  }
}
.arrow-down,
.arrow-down:after {
  display: inline-block;
}
.arrow-down:after {
  border-color: initial;
  border-style: solid;
  border-width: 0 0 1px 1px;
  content: "";
  height: 0.4em;
  right: 5px;
  top: 50%;
  margin-left: 10px;
  -webkit-transform: rotate(-45deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  width: 0.4em;
}
@media (max-width: 1199.98px) {
  .topnav-menu .navbar-nav li:last-of-type .dropdown .dropdown-menu {
    right: 100%;
    left: auto;
  }
}
@media (max-width: 991.98px) {
  .navbar-brand-box .logo-dark,
  .navbar-brand-box .logo-dark span.logo-sm {
    display: block;
  }
  .navbar-brand-box .logo-light {
    display: none;
  }
  .topnav {
    max-height: 360px;
    overflow-y: auto;
    padding: 0;
  }
  .topnav .navbar-nav .nav-link {
    padding: 0.75rem 1.1rem;
  }
  .topnav .dropdown .dropdown-menu {
    background-color: transparent;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-left: 24px;
  }
  .topnav .dropdown .dropdown-menu.dropdown-mega-menu-xl {
    width: auto;
  }
  .topnav .dropdown .dropdown-menu.dropdown-mega-menu-xl .row {
    margin: 0;
  }
  .topnav .dropdown .dropdown-item {
    position: relative;
    background-color: transparent;
  }
  .topnav .dropdown .dropdown-item.active,
  .topnav .dropdown .dropdown-item:active {
    color: #7a7fdc;
  }
  .topnav .arrow-down:after {
    right: 15px;
    position: absolute;
  }
}
